<template>
 <div class="role-table">
    <avue-crud v-bind="bindVal"
        v-on="onEvent"
        v-model="form"
        :page.sync="page">
        <template slot="menuLeft"
            slot-scope="{ size }">
        <!-- row, index -->
        <el-button
          :size="size"
          type="success"
          icon="el-icon-edit"
          @click="onColoneRole"
          >克隆角色功能</el-button
        >
        <!-- $refs.crud.rowEdit(row, index) -->
      </template>
      <template #btn="{row}" >
        <div class="btn_class">
          <el-button
            type="text"
             icon="el-icon-edit"
            @click="onFucAdmin(row, 1)"
            >功能管理</el-button
          >
          <!-- <el-button
            type="text"
             icon="el-icon-edit"
            @click="onFucAdmin(row, 2)"
            >菜单关联</el-button
          > -->
          <!-- <el-button
            type="text"
            icon="el-icon-edit"
            @click="onFucAdmin(row, 3)"
            >角色范围</el-button
          > -->
        </div>
      </template>
    </avue-crud>
    <basic-dialog
        ref="dialog"
        title=""
        :isMaskClose="true"
        width="60%"
      >
      <fun-admin v-if="type === 1 && roleId !== undefined" :roleId="roleId"></fun-admin>
      <menu-admin v-else-if="type === 2 && roleId !== undefined" :roleRangeData="roleRangeData" :roleId="roleId"></menu-admin>
      <role-range v-else-if="type === 3 && roleId !== undefined" :roleId="roleId"></role-range>
    </basic-dialog>
    <basic-dialog
        ref="roleCloenFunc"
        title="克隆角色功能"
        :isMaskClose="true"
        width="60%"
      >
        <avue-form ref="Funcform" v-model="Funcobj" :option="Funcformoption" @submit="Funcsubmit">
        <!-- @reset-change="FuncemptytChange" -->
      </avue-form>
    </basic-dialog>
 </div>
</template>

<script>
let crud = window.$crudCommon({}, {
  name: "role/index",
  pageNumber: 'current',//页码
  pageSize: 'size',//页数
  total: 'total',//总页数
  res:(data)=>{
    return {
      total: data.total,
      data: data.records
    }
  },//列表的结构
})
import funAdmin from "../basic-util-admin/fun-admin.vue"
import menuAdmin from "../basic-util-admin/menu-admin.vue"
import roleRange from "../basic-util-admin/role-range.vue"
import { cloneRolefunc } from "@/api/role/index"
export default {
  name: 'roletable',
  components: {
    funAdmin,
    menuAdmin,
    roleRange
  },
  mixins: [crud],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
  },
  data () {
    return {
      type: null,
      roleId: undefined,
      roleRangeData: [],
      Funcobj: {},
      Funcformoption: {
         column: [
           {
            label: "目标角色克隆到",
            prop: "fromRoleId",
            type:'select',
            labelWidth: 140,
            span: 12,
            dicData: [],
            tip: '目标角色功能【克隆】到当前角色',
            props: {
              label: 'name',
              value: 'id'
            },
             rules: [
                {
                  message: "请选择",
                  required: true,
                },
              ],
          },
          {
            label: "当前角色",
            prop: "toRoleId",
            type:'select',
            labelWidth: 140,
            span: 12,
            dicData: [],
            tip: '目标角色功能【克隆】到当前角色',
            props: {
              label: 'name',
              value: 'id'
            },
             rules: [
                {
                  message: "请选择",
                  required: true,
                },
              ],
          }
        ]
      }
    }
  },
  watch: {
    // options: {
    //   handler (options) {
    //     // 设置true清空echart缓存
    //     this.chart.setOption(options, true)
    //   },
    //   deep: true
    // }
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    listAfter(data) {
      if (data) {
        let column = this.Funcformoption.column
        this.roleRangeData = data.data
        column[0].dicData = data.data
        column[1].dicData = data.data
      }
    },
    onColoneRole() {
      this.Funcobj = {}
      this.$refs.roleCloenFunc.show('克隆角色功能')
    },
    // 角色克隆提交
    Funcsubmit(form,done) {
      cloneRolefunc({
        fromRoleId: form.fromRoleId,
        toRoleId: form.toRoleId
      }).then(({data}) => {
        if (data.result) {
          done()
          this.$message.success('克隆，目标角色功能成功')
          this.$refs.roleCloenFunc.hide('克隆角色功能')
        }
      }).catch(() => {
        done()
      })
    },
    // 按钮操作
    onFucAdmin(row, type) {
      this.roleId = undefined
      /**
       * @type
       * 1 : 功能管理
       * 2 : 关联菜单
       * 3 : 角色范围
       */
      setTimeout(() => {
        this.roleId = row.id
        switch (type) {
          case 1:
            this.type = type
            this.$refs.dialog.show('功能管理')
            break;
          case 2:
            this.type = type
            this.$refs.dialog.show('关联菜单： ' + row.name)
            break;
          case 3:
            this.type = type
            this.$refs.dialog.show('角色范围：' + row.name)
            break;
          default:
            break;
        }
      }, 300)
    }
  }
}
</script>

<style>
  .role-table {
    /* padding: 25px; */
  }
  .avue-group{
    padding-top: 10px;
  }
  .avue-crud__menu {
    padding: 10px;
  }
  .el-button--primary {
    background-color: #e94e46;
    border-color: #e94e46;
  }
  .el-button--primary:focus, .el-button--primary:hover {
    background-color: #e94e46;
    border-color: #e94e46;
    opacity: 0.7;
  }
  .el-button--text {
    color: #e94e46;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #e94e46;
  }
  /* .ui-model .el-drawer__body .avue-group .el-collapse-item__content .el-collapse-item__content .avue-crud__menu, .avue-form__group {
    background: white !important;
  } */
  /* .el-card__body {
    background: #0f1325 !important;
  }
  .role-table .avue-crud .avue-crud__menu {
    background: #0f1325 !important;
  }
  .role-table .avue-crud .avue-form__group {
    background: #0f1325 !important;
  }
  .avue-crud .el-table th {
    background: #0f1325 !important;
    color: #d3d6dd;
  }
  .el-collapse-item__wrap {
    border: none !important;
  }
  .el-collapse {
    border-top: none !important;
  }
  .el-table tr , td {
    background: #0f1325 !important;
    color: #d3d6dd;
  }
  .el-card {
    background: #0f1325 !important;
  } */
  
  /* .el-table, .el-table__expanded-cell {
    background: #0f1325 !important;
  } */
  /* .el-table tr{
      margin: 0;
      padding: 0;
      height: 50px;
  }
  .el-table thead tr{
      background: rgba(5, 58, 152, 0.5);
  }
  .el-table tbody .row-class-fix td:first-child{
      border-left: 1px solid #053A98;
  }
  .el-table td{
      border-bottom: 1px solid #053A98;
      border-right: 1px solid #053A98;
  } */
</style>
