<template>
  <div class="fun-admin">
    <avue-crud v-bind="bindVal"
        v-on="onEvent"
        v-model="form"
        :page.sync="page">
      <template #menu="{row}">
        <!-- <el-switch
          :model="row.asBind === 0 ? '是' : '否'"
          inline-prompt
          active-text="是"
          inactive-text="否"
        /> -->
        <el-button
          v-if="row.asBind === 0" 
          type="text"
          icon="el-icon-open"
          size="small"
          @click="onAuthRolefunc(row)">开启</el-button>
        <el-button type="text"
            v-if="row.asBind === 1" 
            icon="el-icon-close"
            size="small"
            style="color:#f56c6c"
            @click="onAuthRolefunc(row)">关闭</el-button> 
          <!-- $refs.crud.rowEdit(row,index) -->
      </template>
    </avue-crud>
  </div>
</template>

<script>
let crud = window.$crudCommon({}, { name: "basic-util-admin/fun-admin",
  pageNumber: 'current',//页码
  pageSize: 'size',//页数
  total: 'total',//总页数
  res:(data)=>{
    return {
      total: data.total,
      data: data.records
    }
  },//列表的结构
});
import { add, del } from "@/api/basic-util-admin/fun-admin"
export default {
  name: "funAdmin",
  mixins: [crud],
  props: {
     roleId: {
      type: [String, Number],
      default: ''
    },
  },
  methods: {
    //列表前操作方法
    listBefore() {
      if (this.roleId) {
        this.params.roleId = this.roleId
      }
    },
    onAuthRolefunc(row) {
      if (row.asBind === 0) {
        add({
          funcId: row.funcId,
          roleId: this.roleId,
        }).then(({data}) => {
          if (data) {
            this.onEvent['refresh-change']()
          }
        })
      } else {
        del({
          funcId: row.funcId,
          roleId: this.roleId,
        }).then(({data}) => {
          if (data) {
            this.onEvent['refresh-change']()
          }
        })
      }
    }
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
</style>
