<template>
  <div class="role-range">
    <avue-crud v-bind="bindVal"
        v-on="onEvent"
        v-model="form"
        :page.sync="page">
      <template #menu-left="{size}">
        <el-button type="primary" icon="el-icon-plus" @click="onAddBind" :size="size">新增绑定</el-button>
      </template>
      <template #menu="{row}">
        <el-button v-if="row.asBind === 1" type="text" icon="el-icon-delete" style="color:#f56c6c" @click="ondelBind(row)" :size="size">删除</el-button>
        <span v-else>--</span>
      </template>
    </avue-crud>
  </div>
</template>

<script>
// rowKey: 'targetRoleId'
let crud = window.$crudCommon({}, { name: "basic-util-admin/role-range" });
import { add, del } from "@/api/basic-util-admin/role-range"
export default {
  name: "roleRange",
  mixins: [crud],
  props: {
    roleId: {
      type: [String, Number],
      default: ''
    },
  },
  methods: {
    //列表前操作方法
    listBefore() {
      if (this.roleId) {
        this.params.roleId = this.roleId
      }
    },
    addBefore() {
       let form = this.form // 表单提交 form
       let data = [{
         roleId: this.roleId,
         targetRoleId: form.targetRoleName
       }]
       this.form = data
    },
    onAddBind() {
      let selection = this.selectionData
      if (selection && selection.length > 0) {
        let data = selection.map(item => {
          return {
            roleId: this.roleId,
            targetRoleId: item.targetRoleId
          }
        })
        add(data).then(({data}) => {
          if (data.result) {
              this.onEvent['refresh-change']()
          }
        })
      } else {
         this.$message.error('请选择');
      }
    },
    ondelBind(row) {
      del({
        ids : row.id
      }).then(({data}) => {
        if (data.result) {
            this.onEvent['refresh-change']()
        }
      })
    }
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
</style>
